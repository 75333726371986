"use client"

import { motion, useInView } from "framer-motion"
import Image from "next/image"
//https://primereact.org/galleria/
import classNames from "classnames"
import { Galleria } from "primereact/galleria"
import { CSSProperties, useRef } from "react"
import styled from "styled-components"
import "./page.css"

const FadingDiv = styled.div`
  box-shadow: 0 5px 20px 2px rgba(0 0 0 / 90%);
`

const styles: { TextP: CSSProperties } = {
  TextP: {
    textAlign: "justify",
    wordBreak: "break-word",
    hyphens: "auto",
  },
}

const motionImageAnimation = {
  whileInView: { x: 0, opacity: 1 },
  exit: { x: 0, opacity: 1 },
  transition: {
    duration: 1,
    delay: 0.3,
    animationTimingFunction: "easeO",
  },
}

export default function Home(props: any) {
  const basePath = "/images/renderings/"
  const imageData = [
    { srcUrl: basePath + "chair/Chair_Camera_02_FL.png" },
    { srcUrl: basePath + "chair/Chair_Camera_03_BL.png" },
    { srcUrl: basePath + "chair/Chair_Camera_04_Mechanic.png" },
    { srcUrl: basePath + "chair/Chair_Camera_06_Head.png" },
    { srcUrl: basePath + "camera/Camera_F2.png" },
    { srcUrl: basePath + "camera/Camera_B.png" },
    { srcUrl: basePath + "Screwdrivers.jpg" },
    { srcUrl: basePath + "console/Konsole_Rendering_01.jpg" },
    { srcUrl: basePath + "spray_bottle/SprayBottle_001.png" },
    { srcUrl: basePath + "spray_bottle/SprayBottle_005.png" },
  ]

  return (
    <div className="w-full overflow-hidden ">
      <div className="absolute top-0 w-full">
        <Galleria
          className="overflow-hidden h-screen w-full [&_button]:z-10 [&_button:hover]:scale-150 
          [&_button]:transition [&_button]:duration-200"
          value={imageData}
          item={ItemTemplate}
          showThumbnails={false}
          showItemNavigators
          autoPlay
          circular
          transitionInterval={5000}
        />
        <div className=" text-center absolute bottom-12 left-0 right-0  z-10 ">
          <motion.div
            className="bg-primaryColor ml-[15%] w-96 opacity-80"
            whileHover={{
              scale: 1.05,
              transition: { ease: "easeInOut", duration: 0.2 },
            }}
          >
            <FadingDiv>
              <motion.div
                // className="bg-primaryColor shadow-lg  p-6 py-0 ml-[15%] w-96 opacity-80"
                initial={{ x: -180, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{ duration: 1.5, ease: "easeOut" }}
              >
                <h1 className="inline text-2xl text-primaryTextColor ">
                  de Gavarelli
                </h1>
              </motion.div>
            </FadingDiv>
          </motion.div>
        </div>
      </div>
      <div className="mt-[100vh]">
        <div className=" mx-[4%] md:mx-[10%] xl:mx-[15%] [&_section]:mb-12">
          <motion.div
            initial={{ x: -100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 1, animationTimingFunction: "ease" }}
          >
            <h1>Renderings</h1>
          </motion.div>
          <section className="">
            <FloatingImage
              description="Rendering eines Stuhls"
              height={640 * (16 / 9)}
              imageUrl="/images/renderings/chair/Chair_Camera_02_FL.png"
              position="right"
              width={640}
            />
            <p style={styles.TextP}>
              In der heutigen Ära der Digitalisierung spielt die visuelle
              Präsentation von Produkten eine entscheidende Rolle für die
              Kaufentscheidung. In diesem Zusammenhang erweisen sich
              3D-Renderings als äußerst attraktive Alternative zu herkömmlichen
              Produktfotos. Diese innovative Darstellungsform bietet eine Fülle
              von Vorteilen, die speziell für die Zielgruppe der Käufer von
              hoher Relevanz sind. Von realistischer Detailpräzision bis hin zur
              flexiblen Anpassung in verschiedenen Umgebungen.
            </p>
          </section>

          <section className="image-1 ">
            <div className="">
              <h2>Details</h2>
              <FloatingImage
                description="Closeup vom Leder"
                height={640 * (16 / 9)}
                imageUrl="/images/renderings/chair/Chair_Camera_06_Head.png"
                position="left"
                width={640}
              />
              <p style={styles.TextP}>
                In der Welt des digitalen Shoppings ist der erste Eindruck
                entscheidend, und hier kommt die Kunst der Produktpräsentation
                ins Spiel. 3D-Renderings haben sich als Geheimwaffe für die
                Steigerung der Attraktivität von Produkten erwiesen, und zwar
                auf eine Art und Weise, die herkömmliche Produktfotos in den
                Schatten stellt. Die Fähigkeit von 3D-Renderings, Produkte in
                einer realistischen, beeindruckenden Detailgenauigkeit zu
                präsentieren, verleiht ihnen einen unbestreitbaren Vorteil. In
                diesem Kontext werfen wir einen Blick darauf, wie die Anwendung
                von 3D-Renderings nicht nur die Erwartungen der Käufer erfüllt,
                sondern sie regelrecht begeistert, indem sie Produkte in einem
                Licht zeigt, das ihre Attraktivität auf ein neues Niveau hebt.
              </p>
            </div>
          </section>
          <section>
            <FloatingImage
              description="Bedienung eines Paketbriefkastens"
              height={640 * (16 / 9)}
              imageUrl="/images/videos/postbox/Briefkasten_Code.jpg"
              position="right"
              width={640}
            />
            <h2>Werbevideos</h2>
            <p>
              Für Werbung oder zum besseren Verständniss von Abläufen können
              Animationen zur Darstellung von Interaktionen verwendet werden.
              Störende Faktoren, wie das Präsentieren durch eine Person, die bei
              der ausführenden Handlung das Produkt verdeckt, können vermieden
              werden. Dadurch liegt der Fokus auf dem Produkt und der
              ausführenden Aktionen.
            </p>
          </section>
        </div>
      </div>
    </div>
  )
}

function FloatingImage(
  props: Readonly<{
    imageUrl: string
    position: "left" | "right"
    width: number
    height: number
    description: string
    alt?: string
  }>,
) {
  const ref = useRef(null)
  const isInView = useInView(ref, { once: true })

  const classes = classNames({
    "float-right ml-6": props.position == "right",
    "float-left mr-6": props.position == "left",
  })

  const offset = props.position === "left" ? "-100%" : "100%"
  return (
    <motion.div
      ref={ref}
      className={"FloatingImage min-h-36 w-80 md:w-80 xl:w-96 " + classes}
      style={{
        transform: isInView ? "none" : `translateX(${offset})`,
        opacity: isInView ? 1 : 0,
        transition: "all 1s ease 0.3s",
      }}
    >
      {props.imageUrl && (
        <div>
          <div className="mt-2">
            <Image
              alt={props.alt ?? props.description}
              className="w-full aspect-video"
              height={props.height}
              placeholder="empty"
              quality={80}
              src={props.imageUrl}
              width={props.width}
            />
          </div>
          <div className="text-center mt-2 mx-auto">
            <span className="text-sm font-serif font-extralight italic">
              {props.description}
            </span>
          </div>
        </div>
      )}
    </motion.div>
  )
}

function ItemTemplate(item: Readonly<{ srcUrl: string; alt: string }>) {
  return (
    <>
      <div className="absolute left-0 right-0 top-0 bottom-0 bg-neutral-800"></div>
      <Image
        className="h-screen object-cover brightness-[100%]"
        src={item?.srcUrl}
        alt={item?.alt ?? "Ein Bild"}
        width={1920}
        height={1080}
      />
    </>
  )
}

const TextIntersectingImage = styled.div`
  margin-top: 7px;
  margin-bottom: 8px;
  overflow: hidden;
  width: 16rem;
  min-height: 300px;

  @media (width > 640px) {
    width: 24rem;
  }

  @media (width > 960px) {
    width: 28rem;
  }

  .imageholder {
    aspect-ratio: 16/9;
    height: auto;
    overflow: hidden;
    width: 100%;

    img {
      width: 100%;
      aspect-ratio: 16/9;
    }
  }

  .description {
    text-align: center;
    margin: 4px auto 0;
  }
`
